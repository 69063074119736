<template>
    
    <thead>        
        <tr>
            <th>
                <label class="table-check">
                    <input type="checkbox"  v-model="select_export_checkAll" id="checkbox" >
                    <span class="checkbox-table"></span>
                </label>
            </th>
            <th v-if="page !== 'essais_clinique'">{{$t('Card')}}</th>
            
                <template v-for="(item) in column" >
                    
                    <template v-if="item.parent === '0'">
                        <template v-if="item.Status">
                            <th class="sub-table hta-separator" style="padding: 0;" :colspan="children_column(item.code).length" :key="item.code" :code="item.code">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <td :colspan="children_column(item.code).length" >{{item.text}}</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <template v-for="(item2) in children_column(item.code)" :key="item2.code">
                                                <th :code="item2.code" :style="'width:'+item2.width+'px;box-shadow:none; border-top: 2px solid #ddd !important; vertical-align: middle;'">
                                                    <span @click="sort_column({'order_by' : item2.sort_by, 'sort': sort})" :style="item2.sort_by ? 'cursor: pointer;' : ''">
                                                        {{item2.text}}
                                                    </span>
                                                    <em v-if="order_by == item2.sort_by && sort == 'desc' " style="font-size: 16px;" class="fas fa-caret-up"></em>
                                                    <em v-else-if="order_by == item2.sort_by && sort == 'asc' " style="font-size: 16px;" class="fas fa-caret-down"></em>
                                                </th>  
                                            </template>
                                        </tr>
                                    </tbody>
                                </table>    

                            </th> 
                        </template>
                    </template>


                    <template v-else>
                        <template v-if="item.Status">
                            <th :key="item.code" :code="item.code" :style="item.background ? 'background:#'+item.background : ''">
                                <span @click="sort_column({'order_by' : item.sort_by, 'sort': sort})" :style="item.color ? 'color:#'+item.color : '' + item.sort_by ? 'cursor: pointer;' : ''">
                                    {{item.text}}
                                </span>
                                <em v-if="order_by == item.sort_by && sort == 'desc' " style="font-size: 16px;" class="fas fa-caret-up"></em>
                                <em v-else-if="order_by == item.sort_by && sort == 'asc' " style="font-size: 16px;" class="fas fa-caret-down"></em>
                            </th> 
                        </template> 
                    </template>            

                </template> 

        </tr>
    </thead>
</template>

<script>
export default {
    name: "ColumnThead",
    props: {
        page: String,
        column: Array,
        default_column: Array,
        main_page : {
            type: String,
            required: false,
            default: ""
        }
    },
    data() {
        return {
            sort : 'desc',
            order_by : null
        }
    },
    methods : {
        sort_column(params){
            
            if(params['order_by']){

                if(params['sort'] == 'desc'){
                    this.sort = 'asc'
                }
                else{
                    this.sort = 'desc'
                }

                this.order_by = params['order_by']

                let paramObj = {...this.$route.params, ...params}
                paramObj['agency'] = this.$route.query.agency
                if(this.page === 'result'){
                    this.$store.dispatch(`result/result_agency`, paramObj)
                }
                else if(this.page === 'atu_postatu'){
                    this.$store.dispatch(`advanced_result/result_atu_postatu`, paramObj )
                }
                else if(this.page === 'rtu'){
                    this.$store.dispatch(`advanced_result/result_rtu`, paramObj )
                }
                else if(this.page === 'ceesp'){
                    this.$store.dispatch(`advanced_result/result_ceesp`, paramObj )
                }
                else if(this.page === 'transparence'){
                    this.$store.dispatch(`advanced_result/result_transparence`, paramObj )
                }
                else if(this.page === 'transparence_ceesp'){
                    this.$store.dispatch(`advanced_result/result_transparence_ceesp`, paramObj )
                }
                else if(this.page === 'prise_en_charge'){
                    this.$store.dispatch(`advanced_result/result_prise_en_charge`, paramObj )
                }
                else if(this.page === 'essais_clinique'){
                    if(this.main_page !== ''){
                        paramObj['main_page'] = this.main_page
                    }
                    paramObj['tab'] = this.page
                    this.$store.dispatch(`essais_clinique/result_essais_clinique`, paramObj ) 
                }
                else if(this.page === 'prix'){
                    if(this.main_page !== ''){
                        paramObj['main_page'] = this.main_page
                    }   
                    paramObj['tab'] = this.page
                    this.$store.dispatch(`advanced_result/result_prix`, paramObj ) 
                }
            }
            
        }
    },
    computed: {
        children_column(){
            return code => this.default_column.filter(item => item.parent == code) 
        },
        select_export_checkAll: {            
            set(val){ 
                console.log('set...',this.page)
                if(this.page === 'prix'){
                    this.$store.dispatch("advanced_result/prix_select_export", {key: 'checkAll', value: val}) 
                }else if(this.page == 'essais_clinique'){
                    this.$store.dispatch("essais_clinique/select_export", {key: 'checkAll', value: val}) 
                }else if(this.page == 'result'){
                    this.$store.dispatch("result/select_export_checkAll", val)
                }else if (this.page == 'report'){
                    this.$store.dispatch("report/select_export",{key: 'checkAll', value:val})
                }else {
                    this.$store.dispatch("advanced_result/select_export", {key: 'checkAll', value: val}) 
                }
            },
            get(){ 
                console.log('get...',this.page)
                if(this.page === 'prix'){
                     return this.$store.getters['advanced_result/prix_select_export'].checkAll 
                }else if(this.page === 'essais_clinique'){
                     return this.$store.getters['essais_clinique/select_export'].checkAll  
                }else if(this.page === 'result'){
                     return this.$store.getters['result/select_export_checkAll']  ? this.$store.getters['result/select_export_checkAll'] : null
                }else if (this.page === 'report'){
                    return this.$store.getters['report/select_export'].checkAll
                }else {
                    return this.$store.getters['advanced_result/select_export'].checkAll                  
                }
               
            }
        }
         
    }
};
</script>

<style>
</style>