<template>
    <colgroup>
        <col style="width: 30px;" />
        <col style="width: 45px;" v-if="page !== 'essais_clinique'" />
        
        <template v-for="(item) in column" >


            <template v-if="item.parent === '0'">
                <template v-if="item.Status">
                    <template v-for="(item2) in children_column(item.code)" :key="item2.code">
                        <col :style="'width: '+item2.width+'px;'" :code="item2.code"/>
                    </template>
                </template>
            </template>


            <template v-else>
                <template v-if="item.Status">
                    <col :style="'width: '+item.width+'px;'" :key="item.code" :code="item.code" />
                </template> 
            </template>            

        </template>
         
    </colgroup>
</template>
    
<script>
export default {
    name: "ColumnColGroup",
    props: {
        page: String,
        column: Array,
        default_column: Array,
    },
    computed: {
        children_column(){
            return code => this.default_column.filter(item => item.parent == code) 
        }
    },
    mounted() {
         
    },
}
</script>

<style>

</style>